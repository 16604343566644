<template>
  <div>
    <div id="spacing-top"></div>

    <div class="container text-center">
      <div class="page-wrap d-flex flex-row align-items-center">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center">
              <span class="display-1 d-block text-muted">404</span>
              <div class="mb-4 lead text-muted">
                The page you are looking for was not found.
              </div>
              <b-button @click="backToLogin()" variant="primary">Back to Login</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ErrorPage404",
  components: {},
  data() {
    return {
    };
  },
  computed: {},
  methods: {
    backToLogin() {
      this.$store.dispatch("signout");
    }
  },
};
</script>

<style scoped>

#spacing-top {
  padding-top: 80px;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 10px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(73, 73, 73, 0.1);
  border-right: 1.1em solid rgba(73, 73, 73, 0.1);
  border-bottom: 1.1em solid rgba(73, 73, 73, 0.1);
  border-left: 1.1em solid #757171;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-text-title {
  font-size: 22px;
  text-align: center;
  /* color: #757171; */
  font-weight: bold;
}

.loading-text {
  font-size: 16px;
  text-align: center;
  /* color: #757171; */
  font-style: italic;
  
}

</style>
