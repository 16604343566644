<template>
  <div id="login" class="login container">
    <b-overlay
      :show="showOverlay"
      rounded="sm"
      variant="light"
      spinner-variant="secondary"
    >
      <div class="container">
      <b-card class="card">
        <h3 align="justify">
          <b>Login</b>
        </h3>
        <p class="p text-muted">Enter your user credentials to login.</p>
        <b-alert v-model="showAlert" variant="danger" dismissible>{{
          alertMsg
        }}</b-alert>
        <b-form @submit.prevent="onSubmit">
          
          <b-form-group
            class="font-bold"
            id="input-group-1"
            label="Username"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="form.username"
              required
              placeholder="Enter username"
              v-bind:class="{ pwalert: showAlert }"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            class="font-bold"
            id="input-group-2"
            label="Password"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              v-model="form.password"
              type="password"
              placeholder="Enter password"
              required
              v-bind:class="{ pwalert: showAlert }"
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary" block>Login</b-button>
        </b-form>
      </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showAlert: false,
      showOverlay: false,
      alertMsg: "",
    };
  },
  methods: {
    /* eslint-disable */
    onSubmit() {
      const formData = {
        username: this.form.username,
        password: this.form.password,
      };

      this.showOverlay = true;
      this.$store
        .dispatch("signin", formData)
        .then((response) => {
          this.showOverlay = false;
          this.showAlert = false;
          this.alertMsg = "";
          this.$router.push("/");
        })
        .catch((err) => {
          this.showAlert = true;
          this.showOverlay = false;
          this.alertMsg = "User credentials are not correct!"
        });
    }
  },
  mounted() {},
};
</script>

<style scoped>
.login {
  width: 400px;
  padding-top: 60px;
}

.card {
  border-color: #212529;
  border-width: 2px;
}

.p {
  font-size: 14px;
}

.pwalert {
  border-color: rgba(240, 36, 36, 0.397);
  border-width: 1px;
}

.alert {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn .b-icon.bi {
  font-size: 250%;
  vertical-align: middle;
}

.btn-link {
  color: #000000;
}

.text-grey-ligh {
  color: #bdc9d4;
  font-weight: bold;
}

.font-bold {
  font-weight: bold;
}
</style>
