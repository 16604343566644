<template>
  <div>
    <div ref="inputText1" id="player-widget" class="col d-flex justify-content-center">
      <b-card id="player-card" class="mb-0" bg-variant="dark" text-variant="white" style="width: 40rem;">
        <div class="row">
          <div class="col vol-8">
            <div class="mb-1">
              <div v-if="currentTileInfo.tileID != null">
                <small>{{currentTileInfo.tileID}}</small>
                <div style="display:inline" v-if="playerElementsDisabled==false"> 
                  <small class="ml-1 mr-1">|</small>
                  <small>{{currentFrameInfo.date}}</small>
                  <b-icon id="info-icon" icon="info-circle-fill" class="ml-1" scale="0.9" variant="info"></b-icon>
                  <b-popover target="info-icon" triggers="hover" placement="top">
                    <div>
                      <dd class="font-sidebar-key">Cloud level</dd>
                      <dt class="font-sidebar-value mb-2">{{(currentFrameInfo.cloudCoverage).toFixed(2)}}%</dt>
                    </div>         
                    <div>
                      <dd class="font-sidebar-key">Data coverage</dd>
                      <dt class="font-sidebar-value mb-2">{{(100 - currentFrameInfo.dataCoverage).toFixed(2)}}%</dt>
                    </div>         
                  </b-popover>
                </div>
              </div>
              <div v-else>
                <small>No tile selected</small>
              </div>
            </div>
          </div>
          <div class="col col-4 text-right">
            <div v-if="!this.$isMobile()">
            <!-- <small id="quality-label">2K</small> -->
              <b-badge v-if="streamResolution=='2k'" id="quality-badge" v-b-popover.hover.top="'High-Definition Streaming'" title="Quality">2K</b-badge>
              <b-badge v-else-if="streamResolution=='4k'" id="quality-badge" v-b-popover.hover.top="'Ultra High-Definition Streaming'" title="Quality">4K</b-badge>
              <b-badge v-else-if="streamResolution=='11k'" id="quality-badge" v-b-popover.hover.top="'Full Native Streaming'" title="Quality">11K</b-badge>
            </div>
            <div v-else>
              <b-badge v-if="streamResolution=='2k'" id="quality-badge-mobile" v-b-popover.hover.top="'Only 2K streaming is supported on mobile devices'" title="Quality">2K</b-badge>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col text-middle">
            <vue-slider 
              id="timeSlider" 
              class="mb-2" 
              ref="timeSlider"
              :disabled="playerElementsDisabled"  
              v-model="timeSliderValue" 
              v-bind="timeSliderOptions"
              @drag-end="timeSliderdragEnd()"
              @drag-start="timeSliderdragStart()"
              @change="timeSliderChange()"
            ></vue-slider>
          </div>
        </div>
        <div class="row">
          <div class="col-3 my-auto">
            <div>
              <vue-slider 
                id="speedSlider" 
                class="mb-2" 
                ref="speedSlider" 
                v-model="speedSliderValue" 
                v-bind="speedSliderOptions"
                :tooltip-formatter="getSpeedSliderTooltip(speedSliderValue)"
                @change=speedChanged()
              >
              </vue-slider>
              <div id="speed-label"><small>Speed</small></div>
            </div>
            
          </div>
          <div class="col-6 d-flex justify-content-center">
            <div v-if="!$isMobile()">
              <b-button :disabled="playerElementsDisabled" @click="resetStream" size="sm" class="not-focusable mr-1" v-b-tooltip.hover title="Go back to first image" variant="secondary"><b-icon icon="stop" font-scale="1.2"></b-icon></b-button>
              <b-button :disabled="playerElementsDisabled" @click="play" size="sm" class="not-focusable mr-1" v-b-tooltip.hover title="Play/Pause image stream">
                <b-icon v-if="!isPlaying" icon="play" font-scale="1.2"></b-icon>
                <b-icon v-else icon="pause" font-scale="1.2"></b-icon>
              </b-button>
              <b-button :disabled="playerElementsDisabled" @click="nextFrame" size="sm" class="not-focusable mr-1" v-b-tooltip.hover title="Go to next image"><b-icon icon="skip-forward" font-scale="1.2"></b-icon></b-button>
            </div>
            <div v-else>
              <b-button :disabled="playerElementsDisabled" @click="resetStream" size="sm" class="not-focusable mr-1"  variant="secondary"><b-icon icon="stop" font-scale="1.2"></b-icon></b-button>
              <b-button :disabled="playerElementsDisabled" @click="play" size="sm" class="not-focusable mr-1">
                <b-icon v-if="!isPlaying" icon="play" font-scale="1.2"></b-icon>
                <b-icon v-else icon="pause" font-scale="1.2"></b-icon>
              </b-button>
              <b-button :disabled="playerElementsDisabled" @click="nextFrame" size="sm" class="not-focusable mr-1"><b-icon icon="skip-forward" font-scale="1.2"></b-icon></b-button>
            </div>
          </div>
          <div class="col-3 d-flex justify-content-end">
            <div  v-if="!$isMobile()" class="mt-0">
              <b-button :disabled="playerElementsDisabled" @click="downloadSingleImage()" size="sm" v-b-tooltip.hover title="Download GeoTIFF" class="mr-1"><b-icon icon="camera" font-scale="1.2" ></b-icon></b-button>
            </div>
            <div v-else class="mt-0">
              <b-button :disabled="playerElementsDisabled" @click="downloadSingleImage()" size="sm" class="mr-1"><b-icon icon="camera" font-scale="1.2" ></b-icon></b-button>
            </div>
          </div>
        </div>
      </b-card>
      <div v-if="!$isMobile()" id="settings-icon" @click="settingsClicked()" v-b-tooltip.hover title="Stream Quality" v-b-hover="handleHoverSettings">
        <b-icon v-if="isHoveredSettings" icon="gear-fill" variant="light" font-scale="1.2"></b-icon>
        <b-icon v-else icon="gear" variant="light" font-scale="1.2"></b-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";
import vueSlider from 'vue-slider-component';
import { speedData } from "@/settings.js";


export default {
  name: "PlayerWidget",
  components: {
    vueSlider
  },
  data() {
    return {
      isHoveredSettings: false,
      isHoveredCircle: false,
      isPlaying: false,
      speedSliderValue: 2,
      speedSliderOptions: {
        dotSize: 14,
        width: 'auto',
        height: 5,
        min: 0,
        max: 5,
        tooltipPlacement: this.getTooltipPlacement("speedSlider"),
        interval: 1,
      },

      timeSliderValue: 1,
      timeSliderOptions: { 
        dotSize: 14,
        width: 'auto',
        height: 5,
        min: 1,
        max: 1000,
        interval: 1,
        tooltipPlacement: this.getTooltipPlacement("timeSlider"),
      },

      playerElementsDisabled: true,
      speedData: null
    };
  },
  computed: {
    currentTileInfo() {
      return this.$store.getters.getCurrentTileInfo;
    },
    currentFrameInfo() {
      return this.$store.getters.getCurrentFrameData;
    },
    playbackRate() {
      return speedData[this.speedSliderValue].playbackRate;
    },
    speedLabel() {
      return speedData[this.speedSliderValue].label;
    },
    videoLoaded() {
      return this.$store.getters.getVideoLoaded;
    },
    streamResolution() {
      return this.$store.getters.getStreamingQuality;
    },
  },
  mounted() {
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    eventBus.$on("reset-play-button", () => {
      this.isPlaying = false;
    });
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
    },
    handleHoverSettings(hovered) {
      this.isHoveredSettings = hovered;
    },
    handleHoverCircle(hovered) {
      this.isHoveredCircle = hovered;
    },
    resetStream() {
      this.$refs.inputText1.focus();
      this.isPlaying = false;
      eventBus.$emit("video-first-frame");
    },
    play() {
      this.isPlaying = !this.isPlaying;
      this.$refs.inputText1.focus();
      eventBus.$emit("video-toggle-playing");
    },
    nextFrame() {
      this.$refs.inputText1.focus();
      this.isPlaying = false;
      eventBus.$emit("video-next-frame");
    },
    downloadSingleImage() {
      let isMobile = this.$isMobile();
      if (!isMobile) {
        let payloadModal = {
          'tileID' : this.currentTileInfo.tileID,
          'timestamp': this.currentFrameInfo.date,
        }
        eventBus.$emit("show-modal-single-download", payloadModal);
        this.isPlaying = false;
      } else {
        this.isPlaying = false;
        let payloadModal = {
          'tileID' : this.currentTileInfo.tileID,
          'timestamp': this.currentFrameInfo.date,
        }
        eventBus.$emit("download-single-image-without-ref", payloadModal);
      }
    },
    settingsClicked() {
      eventBus.$emit("show-modal-settings");
    },
    getSpeedSliderTooltip(sliderIndex) {
      let isMobile = this.$isMobile();
      if (!isMobile) {
        return speedData[sliderIndex].labelDesktop;
      } else {
        return speedData[sliderIndex].labelMobileDevice;
      }
    },
    speedChanged() {
      //console.log("speed value changed to: ", this.speedSliderValue, " -> rate:", speedData[this.speedSliderValue].playbackRate);
      this.$store.commit("setPlaybackRate", this.playbackRate);
    },
    updateTimeSliderOptions(options) {
      this.timeSliderOptions.max = options.max;
      //this.timeSliderValue = 1;
    },
    // TODO: Talk to Florian
    timeSliderdragStart() { 
      eventBus.$emit("time-slider-drag-start");
    },
    timeSliderChange() {  
      eventBus.$emit("time-slider-change", this.timeSliderValue);
    }, 
    timeSliderdragEnd() {
      eventBus.$emit("time-slider-drag-end", this.timeSliderValue);
    },
    getTooltipPlacement(slider) {
      let isMobile = this.$isMobile();
      if (slider === "speedSlider") {
        if (!isMobile) {
          return "bottom";
        } else {
          return "right";
        }
      } else if (slider === "timeSlider") {
        if (!isMobile) {
          return "left";
        } else {
          return "right";
        }
      }
    },
  },
  watch: {
    currentTileInfo: {
      handler: function (tileInfo) {
        if (tileInfo.totalFrameCount) {
          let options = {
            max : tileInfo.totalFrameCount
          };
          this.updateTimeSliderOptions(options);
        }
      },
      deep: true,
    },
    'currentFrameInfo.frameNumber'(frameNumber){
      //console.log(frameNumber);
      this.timeSliderValue = frameNumber;
    },
    // 'currentTileInfo.tileID'(tileID) {
    //   if (tileID) {
    //     this.playerElementsDisabled = false;
    //   } else {
    //     this.playerElementsDisabled = true;
    //   }
    // }
    videoLoaded(val) {
      console.log("Change player buttons active: ", val);
      this.playerElementsDisabled = !val;
    },
    streamResolution(resolution) {
      this.speedSliderValue = 2;
      let newMax;
      if (resolution === "2k") {
        newMax = 5;
      } else if (resolution === "4k") {
        newMax = 3;
      } else if (resolution === "11k") {
        newMax = 3;
      }
      this.speedSliderOptions.max = newMax;
      this.$store.commit("setPlaybackRate", this.playbackRate);
    } 
  },
};
</script>

<style scoped>
  #player-widget {
    position: absolute;
    bottom: 10px;
    pointer-events: none;
    z-index: 1000;
    opacity: 0.95;
    /*width: 640px !important;*/
  }

  #player-card {
    pointer-events: all;
  }

  #player-card .card-body {
    padding-top: 15px !important;
  }

  #settings-icon {
    position: relative;
    left: -44px;
    top: 18px;
    height: min-content;
    pointer-events: all;
  }

  /* #close-icon {
    position: relative;
    left: -40px;
    top: 18px;
    height: min-content;
    pointer-events: all;
  } */

  #settings-icon:hover {
    cursor: pointer;
  }

   #close-icon:hover {
    cursor: pointer;
  }

  #info-icon {
    color: #8ab4f8 !important;
  }

  #info-icon:hover {
    cursor: pointer;
    /*color: #669df6 !important*/
  }

  .btn:focus {
    outline: none;
    box-shadow: none;
    background-color: #6c757d;
    border-color: #6c757d;
  }

  .btn:focus:hover {
    outline: none;
    background-color: #5a6268;
    border-color: #5a6268;
  }

  #speed-label {
    position: absolute;
    top: 14px;
  }

  #quality-label {
    margin-right: 30px;
  }

  #quality-badge {
    margin-right: 30px;
    background-color: #8ab4f8;
    color: #343a40;
  }

  #quality-badge:hover {
    cursor: pointer;
    /* background-color: #669df6 !important */
  }

  #quality-badge-mobile {
    background-color: #8ab4f8;
    color: #343a40;
  }

</style>

<style>
#timeSlider .vue-slider-process {
  background-color: #8ab4f8 !important;
}

#timeSlider .vue-slider-dot-tooltip-inner {
  border-color: #669df6 !important;
  background-color: #8ab4f8 !important;
}

#speedSlider .vue-slider-process {
  background-color: #ccc !important;
}

#speedSlider .vue-slider-dot-tooltip-inner {
  border-color: #669df6 !important;
  background-color: #8ab4f8 !important;
}

.vue-slider-dot-handle {
  background-color: #8ab4f8 !important;
  box-sizing: border-box;
}
</style>