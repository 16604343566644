import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/store.js";

import Viewer from "@/pages/Viewer";
import Login from "@/components/Login";
import ErrorPage404 from '@/components/ErrorPage404.vue'

Vue.use(VueRouter);

export const routes = [
  { name: "map", path: "/", component: Viewer, meta: { requiresAuth: true } },
  { name: "login", path: "/login", component: Login,  meta: { requiresAuth: false } },
  { path: '*', component: ErrorPage404, meta: {requiresAuth: false}},
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("tryAutoSignIn");
  if (!store.state.auth.access_token && to.meta.requiresAuth) {
    next({ path: "/login"});
    //next({ path: "/login"});
  } else {
    next();
  }
});

export default router;
