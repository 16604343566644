<template>
  <div>
    <b-modal 
      size="md" 
      ref="modalSettings" 
      title="Settings" 
      no-close-on-backdrop
      ok-title="Save"
      @ok="handleSubmit"
    >
      <b-form-group>
        <label class="label-style">Streaming Quality</label>
        <b-form-select 
          v-model="qualityValue" 
          :options="qualityOptions">
        </b-form-select>
        <div style="text-align: right;">
          <small tabindex="-1" class="form-text text-muted">Default: 1980x1980 High-Definition Streaming</small>
        </div>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";
import { qualityDesktop, qualityMobileDevice } from "@/settings.js";

export default {
  name: "ModalDialogSettings",
  data() {
    return {
      qualityValue: null,
      qualityOptions: [],
      quality: null
    }
  },
  computed: {
    formDataValid() {
      return ((this.targetCRSSelected != null) && (this.imageTypeSelected != null))
    },
    streamingQuality() {
      return this.$store.getters.getStreamingQuality;
    },
    browser() {
      return this.$store.getters.getBrowserType;
    }
  },
  methods: {
    initForm() {
    },
    showModal() {
      this.$refs["modalSettings"].show();
    },
    hideModal() {
      this.$refs["modalSettings"].hide();
    },
    handleSubmit() {
      this.$store.commit("setStreamingQuality", this.qualityValue);
    },
    initQualityOptions() {
      this.qualityOptions = [];
      let isMobile = this.$isMobile();
      if (!isMobile) {
        this.initQualityOptionsDesktop();
      } else {
        this.initQualityOptionsMobile();
      }
    },
    initQualityOptionsDesktop() {
      let browser = this.browser;
      
      if (browser === "chrome") {
        this.quality = qualityDesktop.chrome;
      } else if (browser === "safari") {
        this.quality = qualityDesktop.safari;
      } else {
        this.quality = qualityDesktop.other;
      }

      var ref = this;
      this.quality.forEach(function (element) {
        ref.qualityOptions.push({ value: element.value, text: element.text },)
      });
    },
    initQualityOptionsMobile() {
      this.quality = qualityMobileDevice;

      var ref = this;
      this.quality.forEach(function (element) {
        ref.qualityOptions.push({ value: element.value, text: element.text },)
      });
    }
  },
  created: function() {
    eventBus.$on("show-modal-settings", () => {
      this.showModal();
      this.initQualityOptions();
      this.qualityValue = this.streamingQuality;
    });
    
  },
  mounted() {
  }
};
</script>

<style>
ul.a {
  list-style-type: square;
}

.label-style {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 4px;
}
</style>
