import Vue from "vue";
import Vuex from "vuex";
import router from "../router/router.js";
import { users } from "@/settings.js";
import CryptoJS from 'crypto-js';
import { eventBus } from "@/main.js";

Vue.use(Vuex);

/* eslint-disable */
const store = new Vuex.Store({
  state: {
    auth: {
      access_token: null,
    },
    streamingQuality: null,
    playbackRate: null,
    toggleLoadingView: false,
    toggleLoadingViewProgress: false,
    extractedFrames: 0,
    extractedFramesTotal: 0,
    currentTileInfo: {
      tileID: null,
      tileBoundsMercator3857: null,
      tileBoundsMercator4326: null,
      tileBoundsUtm: null,
      totalFrameCount: null,
    },
    currentFrameData: {
      frameNumber: null,
      date: '',
      cloudCoverage: null,
      dataCoverage: null
    },
    videoLoaded: false,
    browserType: null,
    isIOs: false,
    viewerDisabled: false,
    s2Tiles: null 
  },
  mutations: {
    authUser(state, userData) {
      state.auth.access_token = userData.access_token;
    },
    setStreamingQuality(state, quality) {
      state.streamingQuality = quality;
    },
    setPlaybackRate(state, playbackRate) {
      state.playbackRate = playbackRate;
    },
    setToggleLoadingView(state, payload) {
      state.toggleLoadingView = payload;
    },
    setToggleLoadingViewProgress(state, payload) {
      state.toggleLoadingViewProgress = payload;
    },
    setCurrentTileInfo(state, payload) {
      state.currentTileInfo.tileID = payload.tileID;
      state.currentTileInfo.tileBoundsMercator3857 = payload.tileBoundsMercator3857;
      state.currentTileInfo.tileBoundsMercator4326 = payload.tileBoundsMercator4326;
      state.currentTileInfo.tileBoundsUtm = payload.tileBoundsUtm;
    },
    setCurrentFrameData(state, payload) {
      state.currentFrameData.frameNumber = payload.frameNumber;
      state.currentFrameData.date = payload.date;
      state.currentFrameData.cloudCoverage = payload.cloudCoverage;
      state.currentFrameData.dataCoverage = payload.dataCoverage;
    },
    setCurrentTileFrameCount(state, totalFrameCount) {
      state.currentTileInfo.totalFrameCount = totalFrameCount;
    },
    setVideoLoaded(state, loadingState) {
      state.videoLoaded = loadingState;
    },
    setIsIOs(state, isIOs) {
      state.isIOs = isIOs;
    },
    setBrowserType(state, browserType) {
      state.browserType = browserType;
    },
    setViewerDisabled(state, disabled) {
      state.viewerDisabled = disabled;
    },
    setExtractedFrames(state, extractedFrames) {
      state.extractedFrames = extractedFrames;
    },
    setExtractedFramesTotal(state, extractedFramesTotal) {
      state.extractedFramesTotal = extractedFramesTotal;
    },
    setS2Tiles(state, tiles) {
      state.s2Tiles = tiles;
    }
  },
  actions: {
    signin({ commit }, authData) {
      return new Promise((resolve, reject) => {
        let username = authData.username;
        let password = authData.password;

        users.forEach(element => {
          if (element.username === username) {
            if (element.password === password) {

              let passphrase = "secretPassphrase";
              let today = new Date();
              let dateStr = today.getFullYear()+ '-' +(today.getMonth()+1) + '-' + today.getDate();
              const encryptedDateStr = CryptoJS.HmacSHA256(dateStr, passphrase).toString();
              let access_token = encryptedDateStr;

              commit("authUser", {access_token: access_token});
              localStorage.setItem('access_token', access_token);

              // Show a modal dialog on the startup
              setTimeout(function(){ eventBus.$emit("show-modal-startup"); }, 500);

              resolve();
            }
          }
        });
        reject();
      });
    },
    signout({ commit }) {
      commit("authUser", {
        access_token: null,
      });
      localStorage.removeItem('access_token');
      router.replace("/login");
    },
    tryAutoSignIn({ commit, dispatch }) {
      console.log("tryAutoSignIn");
      const token = localStorage.getItem('access_token');
      if (!token) {
        return;
      } else {
        let passphrase = "secretPassphrase";
        let today = new Date();
        let dateStr = today.getFullYear()+ '-' +(today.getMonth()+1) + '-' + today.getDate();
        const encryptedDateStr = CryptoJS.HmacSHA256(dateStr, passphrase).toString();
        
        let access_token = encryptedDateStr;
        if (token != access_token) {
          return;
        }
        
        commit('authUser', {
          access_token: access_token
        });
      }
    }
  },
  getters: {
    loggedIn(state) {
      return state.auth.access_token != null;
    },
    getStreamingQuality(state) {
      return state.streamingQuality;
    },
    getPlaybackRate(state) {
      return state.playbackRate;
    },
    getToggleLoadingView(state) {
      return state.toggleLoadingView;
    },
    getToggleLoadingViewProgress(state) {
      return state.toggleLoadingViewProgress;
    },
    getCurrentFrameData(state) {
      return state.currentFrameData;
    },
    getCurrentTileInfo(state) {
      return state.currentTileInfo;
    },
    getVideoLoaded(state) {
      return state.videoLoaded;
    },
    getIsIOs(state) {
      return state.isIOs;
    },
    getBrowserType(state) {
      return state.browserType;;
    },
    getViewerDisabled(state) {
      return state.viewerDisabled;
    },
    getExtractedFrames(state) {
      return state.extractedFrames;
    },
    getExtractedFramesTotal(state) {
      return state.extractedFramesTotal;
    },
    getS2Tiles(state) {
      return state.s2Tiles;
    }
  },
});

/* eslint-enable */
export default store;
