<template>
  <div>
    <b-navbar id="top-nav-bar" fixed="top" type="dark" variant="dark">
      <!-- <b-navbar-brand @click="reloadPage()" href="#">FastAScanner</b-navbar-brand> -->
      <b-navbar-brand @click="reloadPage()" href="#">
        <img v-if="!$isMobile()" id="navbar-img" src="./../assets/fas-logo_desktop.png" alt="">
        <img v-else id="navbar-img" src="./../assets/fas-logo_mobile.png" alt="">
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="loggedIn">
          <b-nav-item @click="changeLocation('europe')" href="#">Europe</b-nav-item>
          <b-nav-item @click="changeLocation('africa')" href="#">Africa</b-nav-item>
          <b-nav-item @click="changeLocation('turkey')" href="#">Turkey</b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto" v-if="loggedIn">
          <!-- <b-navbar-nav class="ml-auto"> -->
          <b-nav-item v-if="!$isMobile()" v-b-tooltip.hover title="Timeseries export" :disabled="viewerDisabled">
            <b-icon icon="download" aria-hidden="true" @click="downloadClicked()"></b-icon>
          </b-nav-item>
          <b-nav-item v-b-tooltip.hover title="User manual">
            <b-icon icon="journal-text" aria-hidden="true" @click="showManual()"></b-icon>
          </b-nav-item>
          <b-nav-item v-b-tooltip.hover title="Logout">
            <b-icon icon="box-arrow-in-right" aria-hidden="true" @click="logout()"></b-icon>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";

export default {
  name: "Navbar",
  data() {
    return {};
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    viewerDisabled() {
      return this.$store.getters.getViewerDisabled;
    }
  },
  methods: {
    downloadClicked() {
      eventBus.$emit("show-modal-timeseries-download");
    },
    logout() {
      this.$store.dispatch("signout");
    },
    reloadPage() {
        this.$router.go();
    },
    changeLocation(country) {
      eventBus.$emit("update-location", country);
    },
    showManual() {
      let pdfPath = "/Manual_FastAScanner.pdf";
      window.open(pdfPath, '_blank');
    }
  }
}
</script>

Manual_FastaScanner

<style scoped>
#navbar-img {
  height: 30px;
}

.nav-link {
  padding-top: 0px;
  padding-bottom: 0px;
}

.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.5)
}

.navbar-dark .navbar-nav .nav-link:focus:hover {
  color: rgba(255, 255, 255, 0.75);
}

.router-link-active{
  font-weight: 600;
  color: #f0f1f0 !important;
}

</style>