<template>
  <div>
    <b-modal 
      size="md" 
      ref="modalSingleDownload" 
      title="Download Single Image" 
      no-close-on-backdrop
      ok-title="Download"
      :ok-disabled="!formDataValid"
      @ok="handleSubmit"
    >
      <b-form-group>
        <label class="label-style">Tile-ID</label>
        <b-form-input
          disabled
          v-model="tileId"
          placeholder="Select tile"
          autocomplete="off"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <label class="label-style">Date</label>
        <b-form-datepicker
          v-model="timestamp"
          reset-button
          disabled
        >
        </b-form-datepicker>
      </b-form-group>

      <b-form-group>
        <label class="label-style">Image Type</label>
        <b-form-select v-model="imageTypeSelected" :options="imageTypeOptions">
          <template #first>
            <b-form-select-option :value="null" disabled>Select image type</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group>
        <label class="label-style">Target CRS</label>
        <b-form-select v-model="targetCRSSelected" :options="targetCRSOptions">
          <template #first>
            <b-form-select-option :value="null" disabled>Select target CRS</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";

export default {
  name: "ModalDialogSingleDownload",
  data() {
    return {
      tileId: '',
      timestamp: null,
      targetCRSSelected: null,
      targetCRSOptions: [
        { value: 'epsg3857', text: 'EPSG 3857 (Default)' }, // auswaehlen
        { value: 'utmWSG84', text: 'UTM WSG84' },
        { value: 'epsg4326', text: 'EPSG 4326' },
      ],
      imageTypeSelected: null,
      imageTypeOptions: [
        { value: 'tci', text: 'True color image' },
        { value: 'bands', text: 'Bands (B3, B4, B8)', disabled: true }
      ]
    }
  },
  computed: {
    formDataValid() {
      return ((this.targetCRSSelected != null) && (this.imageTypeSelected != null))
    }
  },
  methods: {
    initForm(data) {
      this.tileId = data.tileID;
      this.timestamp = data.timestamp;
      
      this.imageTypeSelected = 'tci';
      this.targetCRSSelected = 'epsg3857';
    },
    clearForm() {
      this.tileId = '';
      this.timestamp =  null;
      this.targetCRSSelected = null;
      this.imageTypeSelected = null;
    },
    showModal() {
      this.$refs["modalSingleDownload"].show();
    },
    hideModal() {
      this.$refs["modalSingleDownload"].hide();
    },
    handleSubmit() {
      let payload = {
        'tile': this.tileId,
        'timestamp': this.timestamp,
        'targetCRS': this.targetCRSSelected,
        'imageType': this.imageTypeSelected,
      }
      eventBus.$emit("extract-single-frame", payload); 
    }
  },
  created: function() {
    eventBus.$on("show-modal-single-download", (data) => {
      this.clearForm();
      this.initForm(data);
      this.showModal();
    });
  }
};
</script>

<style>
ul.a {
  list-style-type: square;
}

.label-style {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 4px;
}

</style>
