export const qualityDesktop = {
  chrome: [
    { id: "0", value: "2k", text: "1980x1980 High-Definition Streaming" },
    { id: "1", value: "4k", text: "4K Ultra High-Definition Streaming" },
    { id: "2", value: "11k", text: "11K Full Native Streaming" },
  ],
  safari: [
    { id: "0", value: "2k", text: "1980x1980 High-Definition Streaming" },
  ],
  other: [
    { id: "0", value: "2k", text: "1980x1980 High-Definition Streaming" },
    { id: "1", value: "4k", text: "4K Ultra High-Definition Streaming" },
  ]
};

export const qualityMobileDevice = [
  { id: "0", value: "2k", text: "1980x1980 High-Definition Streaming" },
];

export const users = [
  {'id': '0', 'username': 'CSC', 'password': 'streamlinedAccess'},
]

export const speedData = {
  0: { "playbackRate": 0.0625, "labelDesktop": "4 seconds between images", "labelMobileDevice" : "4 sec/image"},
  1: { "playbackRate": 0.125, "labelDesktop": "2 seconds between images", "labelMobileDevice" : "2 sec/image"},
  2: { "playbackRate": 0.25, "labelDesktop": "1 second between images", "labelMobileDevice" : "1 sec/image"},
  3: { "playbackRate": 0.5, "labelDesktop": "0.5 seconds between images", "labelMobileDevice" : "0.5 sec/image"},
  4: { "playbackRate": 1, "labelDesktop": "0.25 seconds between images", "labelMobileDevice" : "0.25 sec/image"},
  5: { "playbackRate": 2, "labelDesktop": "0.125 seconds between images", "labelMobileDevice" : "0.125 sec/image"},
}

export const maxDateRange = 90;