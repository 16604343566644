<template>
  <div>
    <b-modal 
      size="md" 
      ref="modalTimeseriesDownload" 
      title="Download Timeseries" 
      no-close-on-backdrop
      ok-title="Download"
      @ok="handleSubmit"
      :ok-disabled="lockDialog"
    >
      <b-overlay :show="this.s2DataLoading" rounded="sm" variant="light" spinner-variant="secondary">
        <!-- <p class="pt-2" style="font-size:14px">
          <em>
            This function is disabled for the demo. 
          </em>
        </p> -->
        <b-alert v-model="showDateRangeWarning" show dismissible variant="warning" @dismissed="showDateRangeWarning=false">
          <div class="alert-heading"><strong>Warning! </strong>{{errTxt}}</div>
        </b-alert>

        <b-badge variant="info" class="mb-3">Quality: {{this.streamResolution}}</b-badge>

        <b-form-group>
          <label class="label-style">Tile-ID</label>
          <list-select
            :list="tileList"
            @select="onSelect"
            :selected-item="selectedTile"
            option-value="code"
            option-text="name"
            placeholder="Select tile"
          >
          </list-select>
        </b-form-group>
        
        <b-form-group>
          <label class="label-style">Start Date</label>
          <b-icon id="info-icon" icon="info-circle-fill" class="ml-1" scale="0.9" variant="info" v-b-popover.hover=tooltipTxt></b-icon>
          <b-form-datepicker
            id="datepicker-begin"
            v-model="startDate"
            reset-button
            min="2017-01-01"
            max="2021-12-31"
          >
          </b-form-datepicker>
        </b-form-group>

        <b-form-group>
          <label class="label-style">End Date</label>
          <b-form-datepicker
            id="datepicker-end"
            v-model="endDate"
            reset-button
            min="2017-01-01"
            max="2021-12-31"
          >
          </b-form-datepicker>
        </b-form-group>

        <b-form-group>
          <label class="label-style">Image Type</label>
          <b-form-select v-model="imageTypeSelected" :options="imageTypeOptions">
            <template #first>
              <b-form-select-option :value="null" disabled>Select image type</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group>
          <label class="label-style">Target CRS</label>
          <b-form-select v-model="targetCRSSelected" :options="targetCRSOptions">
            <template #first>
              <b-form-select-option :value="null" disabled>Select target CRS</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>

      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";
import { ListSelect } from 'vue-search-select'
import axios_services from '@/axios/axios-services.js'
import { maxDateRange } from "@/settings.js";

export default {
  name: "ModalDialogTimeseriesDownload",
  components: {
    ListSelect,
  },
  data() {
    return {
      tileList: [],
      selectedTile: {},
      startDate: null,
      endDate: null,
      targetCRSSelected: null,
      targetCRSOptions: [
        { value: 'epsg3857', text: 'EPSG 3857 (Default)' },
        { value: 'utmWSG84', text: 'UTM WSG84' },
        { value: 'epsg4326', text: 'EPSG 4326' },
      ],
      imageTypeSelected: null,
      imageTypeOptions: [
        { value: 'tci', text: 'True color image' },
        { value: 'bands', text: 'Bands (B3, B4, B8)', disabled: true}
      ],
      showDateRangeWarning: false,
      errTxt: "",
      s2Data: null,
      s2DataLoading: false,
      tooltipTxt: ""
    }
  },
  computed: {
    formDataValid() {
      return (
        (this.targetCRSSelected != null) && 
        (this.imageTypeSelected != null) && 
        (this.startDate != "") &&
        (this.endDate != "") &&
        (Object.keys(this.selectedTile).length != 0 )
      )
    },
    lockDialog() {
      return (this.s2DataLoading || !this.formDataValid)
    },
    streamResolution() {
      if (this.$store.getters.getStreamingQuality != null) {
        return this.$store.getters.getStreamingQuality.toUpperCase() + " Streaming";
      } else {
        return "";
      }
    },
    currentTileInfo() {
      return this.$store.getters.getCurrentTileInfo;
    }
  },
  methods: {
    initForm() {
      // Initialize tile list for selection widget
      this.s2Tiles = [];
      this.tileList = [];

      let s2Tiles = this.$store.getters.getS2Tiles;
      s2Tiles.forEach(element => {
        let d = {
          code: element.tile_id.toLowerCase(), name: element.tile_id
        };
        this.tileList.push(d);
      });

      this.imageTypeSelected = "tci";
      this.targetCRSSelected = "epsg3857"

      this.tooltipTxt = `Maximum date range: ${maxDateRange} days`;

      console.log("current: ", this.currentTileInfo);
      console.log(this.s2Tiles)
      if (this.currentTileInfo.tileID != null) {
        const tileItem = this.tileList.find(a => a.code == this.currentTileInfo.tileID.toLowerCase());
        console.log(tileItem);
        var d = {
          code: tileItem.code,
          name: tileItem.name
        }
        this.onSelect(d);
      }
      
    },
    clearForm() {
      this.selectedTile = {};
      this.startDate =  "";
      this.endDate =  "";
      this.targetCRSSelected = null;
      this.imageTypeSelected = null;
      this.showDateRangeWarning = false;
    },
    showModal() {
      this.$refs["modalTimeseriesDownload"].show();
    },
    hideModal() {
      this.$refs["modalTimeseriesDownload"].hide();
    },
    handleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()

      // Do some error handling

      var diffTime;
      var diffDays;

      if (this.startDate >= this.endDate) {
        console.log("error: date range not correct");
        this.errTxt = "Dates (start, end) are not correct";
        this.showDateRangeWarning = true;
        return;
      }

      diffTime = Math.abs(new Date(this.endDate) - new Date(this.startDate ));
      diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > maxDateRange) {
        console.log("error: date range too large");
        this.errTxt = `Date span in the demo version is limited to a maximum of ${maxDateRange} days.`;
        this.showDateRangeWarning = true;
        return;
      }

      let closestStart = this.getClosestStartDate(this.startDate);
      let closestEnd = this.getClosestEndDate(this.endDate);

      if (closestStart > this.endDate) {
        console.log("error: No images found for extraction (closes_start > submitted end).");
        this.errTxt = "No images found for extraction. Please change the dates.";
        this.showDateRangeWarning = true;
        return;
      }
      if (closestEnd < this.startDate) {
        console.log("error: No images found for extraction (closes_end < submitted start)");
        this.errTxt = "No images found for extraction. Please change the dates.";
        this.showDateRangeWarning = true;
        return;
      }
      
      // Get frames for the date objects
      let startEntry = this.s2Data.find(o => o.sensing_time === closestStart);
      let endEntry = this.s2Data.find(o => o.sensing_time === closestEnd);

      // Provide payload if everything is fine
      let payload = {
        'tile': this.selectedTile,
        'enteredStartDate': this.startDate,
        'enteredEndDate': this.endDate,
        'detectedStartDate': startEntry.sensing_time,
        'detectedStartDateFrame': startEntry.image_number + 1,
        'detectedEndDate': endEntry.sensing_time,
        'detectedEndDateFrame': endEntry.image_number + 1,
        'targetCRS': this.targetCRSSelected,
        'imageType': this.imageTypeSelected,
      };

      // console.log("timeseries modal payload: ", payload);

      // Hide the modal manually
      this.$nextTick(() => {
        this.hideModal();
        this.showDateRangeWarning = false;
      })

      // Trigger timeseries extraction function and send payload
      eventBus.$emit("extract-timeseries", payload);
      
    },
    loadS2TileData(tileID) {
      this.s2DataLoading = true;
      axios_services
        .get(`/metadata/s2/tci/${tileID}`)
        .then((response) => {
          this.s2Data = response.data.images;
          this.s2DataLoading = false;
        })
        .catch((err) => {
          console.log("error: not able to load tile data for " + tileID + " - " + err);    
          this.s2DataLoading = false;
        });
    },
    /**
     * This function is called when the search box selection changes
     */
    onSelect (selectedTile) {
      this.selectedTile = selectedTile;
      if (this.selectedTile.name != undefined) {
        this.loadS2TileData(this.selectedTile.name);
      } else {
        this.clearForm();
      }
    },
    getClosestStartDate(dateValue) {
      let closestDate = "";
      for (let element of this.s2Data) {
        let d = element.sensing_time.split('T')[0];
        let isDateGreater = (new Date(d).getTime() >= new Date(dateValue).getTime());
        if (isDateGreater) {
          closestDate = element.sensing_time;
          break;
        }
      }
      // console.log("closests start date to ", dateValue, " - ", closestDate);
      return closestDate;
    },
    getClosestEndDate(dateValue) {
    let closestDate = "";
    var s2arrNewOrder = this.s2Data.slice().reverse();
    for (let element of s2arrNewOrder) {
      let d = element.sensing_time.split('T')[0];
      let isDateSmaller = (new Date(d).getTime() <= new Date(dateValue).getTime());
      if (isDateSmaller) {
        closestDate = element.sensing_time;
        break;
      }
    }
    // console.log("closests end date to ", dateValue, " - ", closestDate);
    return closestDate;
  }
  },
  created: function() {
    eventBus.$on("show-modal-timeseries-download", () => {
      this.clearForm();
      this.initForm();
      this.showModal();
    });
  },
  watch: {}
};
</script>

<style>
ul.a {
  list-style-type: square;
}

.label-style {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 4px;
}

.ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text {
    color: #6c757d  !important;
    font-size: 15px
}

.ui.dropdown, .ui.dropdown input {
    font-size: 14px !important;
}
</style>
