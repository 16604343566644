<template>
  <div>
    <b-modal
      id="bv-modal-startup"
      size="lg"
      ref="modalStartup"
      title="Welcome to FastAScanner"
      hhide-footer
    >
      <p class="pt-0 font-italic">
        <small>
          Welcome to the demonstration of the web application component the CSC FastAScanner - you can find a detailed description and 
          demonstrations on all the other components of the CSC Streamlined Data Access
          <a target="_blank" href="https://csc-streamlined.geoville.com/start/">here</a>.
        </small>
      </p>

      <p class="pt-0 font-italic">
        <small>
        Requirements for the FastAScanner demo version:
        <ul>
          <li>Desktops: All features are available on a Chrome Browser on Windows &amp; Linux desktops. For Mac systems please use a version greater or equal OS 15.</li>
          <li>Mobile devices: For Android devices and iPhones video resolutions and features are limited.</li>
        </ul>
        </small>
      </p>

      <p class="pt-2">
        <small>
          A Web-Application which acts as “Fast Scanner” of mission archives
          with the functionality to play full and reduced resolution streams
          depending on the client device (Mobile/Tablet or Laptop/Desktop). In
          addition the demo Web-Application can extract and store selected parts
          (single scenes or complete time-ranges) of the streams into
          georeferenced GeoTIFFs on client side with the capability to reproject
          the data to a given coordinate reference system (CRS) on the fly. Note
          that for the demo true colour imagery only is presented for two demo
          regions for five years of Sentinel 2 L2A data.
        </small>
      </p>  
      
      <div class="row pt-2">
        <div class="col text-center">
          <!-- <b-img src="https://www.w3schools.com/bootstrap/paris.jpg" width="450px"></b-img> -->
          <b-img src="demo_sites_white.png" width="450px"></b-img>
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="hideModal">
            OK
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";

export default {
  name: "ModalDialogStartup",
  methods: {
    showModal() {
      this.$refs["modalStartup"].show();
    },
    hideModal() {
      this.$refs["modalStartup"].hide();
    },
  },
  created: function () {
    eventBus.$on("show-modal-startup", () => {
      this.showModal();
    });
  },
};
</script>

<style>
img {
  max-width: 100%;
}
</style>
