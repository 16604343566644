<template>
  <div>
    <Navbar />
    <router-view />
    <GlobalComponents />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import GlobalComponents from "./components/GlobalComponents.vue";
import { speedData } from "@/settings.js";
import loam from "loam";
import browser from 'browser-detect';

export default {
  name: "App",
  components: {
    Navbar,
    GlobalComponents,
  },
  created() {},
  data() {
    return {};
  },
  mounted() {
    this.initSettings();
    if (!this.$isMobile()) {
      this.initLoam();
    }
    this.initIsIOs();
    this.setBrowserType();
  },
  methods: {
    initSettings() {
      this.$store.commit("setPlaybackRate", speedData[2].playbackRate);
      this.$store.commit("setStreamingQuality", "2k");
    },
    initLoam() {
      loam.initialize("/").then(() => {
        //console.log("loam initialized");
      });
    },
    initIsIOs() {
      let isIOs;

      if (!this.$isMobile()) {
        isIOs = false;
        const browserResult = browser();
        if (browserResult.name === "safari") {
          isIOs = true;
        } else {
          isIOs = false;
        }
        //console.log("isIOS: ", isIOs);
      } 
      else {
        isIOs = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
          // /iPad|iPhone|iPod/.test(navigator.userAgentData.platform) ||
          // (navigator.userAgentData.platform === "MacIntel" && navigator.maxTouchPoints > 1);
      }

      this.$store.commit("setIsIOs", isIOs);
    },
    setBrowserType() {
      const browserResult = browser();
      //console.log("Browser detected: ", browserResult.name)
      this.$store.commit("setBrowserType", browserResult.name);
    }
  },
};
</script>

<style scoped>
</style>

