<template>
  <div>
    <b-modal
      no-close-on-esc
      id="modal-dialog-loading-view"
      :hide-footer="true"
      :hide-header="true"
      centered
      no-close-on-backdrop
    >
      <div class="container">
        <div class="loader">Loading...</div>
        <div class="mt-4 text-muted loading-text-title">
          Your download is being prepared
        </div>
        <div class="mt-1 text-muted loading-text">
          This process may take some time
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalLoadingView",
  components: {},
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    toggleLoadingView() {
      return this.$store.getters.getToggleLoadingView;
    }
  },
  mounted() {},
  beforeDestroy: function () {},
  methods: {
    showLoadingView() {
      this.$bvModal.show('modal-dialog-loading-view');
    }, 
    hideLoadingView() {
      this.$bvModal.hide('modal-dialog-loading-view');
    }
  },
  watch: {
    toggleLoadingView(newVal) {
      if (newVal) {
        this.showLoadingView();
      } else {
        this.hideLoadingView();
      }
    }
  }
};
</script>

<style scoped>
#modal-dialog-wait {
  pointer-events: none;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  margin: 10px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(73, 73, 73, 0.1);
  border-right: 1.1em solid rgba(73, 73, 73, 0.1);
  border-bottom: 1.1em solid rgba(73, 73, 73, 0.1);
  border-left: 1.1em solid #757171;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-text-title {
  font-size: 18px;
  text-align: center;
  font-weight: 800;
}

.loading-text {
  font-size: 14px;
  text-align: center;
  font-style: italic;
}
</style>

<style>
#modal-dialog-loading-view {
  overflow-y: hidden !important;
}
</style>
