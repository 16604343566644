<template>
  <div id="map-container">
    <div v-if="viewerDisabled" id="disable-viewer"></div>
    <Map/>
    <PlayerWidget/>
  </div>
</template>

<script>
import Map from "@/pages/Viewer/Map.vue";
import PlayerWidget from '@/pages/Viewer/Player.vue';

export default {
  name: "Viewer",
  components: {
    Map,
    PlayerWidget,
  },
  data() {
    return {
      sidebarOpened: true,
      invalidateSize: null,
      show: true
    };
  },
  computed: {
    viewerDisabled() {
      return this.$store.getters.getViewerDisabled;
    }
  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    /**
     * This function toggles the sidebar container
     */
    toggleSidebar() {
      this.sidebarOpened = !this.sidebarOpened;
    },
    /**
     * This function re-renders the leaflet map (after container resize event)
     */
    transitionendHandler() {
      this.invalidateSize = this.sidebarOpened;
    },
    handleHover(hovered) {
      this.isHovered = hovered;
    },
    play() {
      this.isPlaying = !this.isPlaying;
    }
  },
};
</script>

<style scoped>
#map-container {
  position: absolute;
  height: calc(100% - 56px) !important;
  width: 100% !important;
}
#disable-viewer {
  height: 100%;
  position: absolute;
  opacity: 0.1;
  z-index: 2000;
  width: 100%;
  background-color: #ffffff;
}
</style>
