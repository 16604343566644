<template>
  <div>
    <ModalDialogSingleDownload/>
    <ModalDialogTimeseriesDownload/>
    <ModalDialogSettings/>
    <ModalLoadingView/>
    <ModalLoadingViewProgress/>
    <ModalStartup/>
  </div>
</template>

<script>
import Vue from "vue";

import ModalDialogSingleDownload from "@/pages/Modals/ModalDialogSingleDownload.vue"
import ModalDialogTimeseriesDownload from "@/pages/Modals/ModalDialogTimeseriesDownload.vue"
import ModalDialogSettings from "@/pages/Modals/ModalDialogSettings.vue"
import ModalLoadingView from "@/pages/Modals/ModalLoadingView.vue"
import ModalLoadingViewProgress from "@/pages/Modals/ModalLoadingViewProgress.vue"
import ModalStartup from "@/pages/Modals/ModalDialogStartup.vue"

Vue.component("ModalDialogSingleDownload", ModalDialogSingleDownload);
Vue.component("ModalDialogTimeseriesDownload", ModalDialogTimeseriesDownload);
Vue.component("ModalDialogSettings", ModalDialogSettings);
Vue.component("ModalLoadingView", ModalLoadingView);
Vue.component("ModalLoadingViewProgress", ModalLoadingViewProgress);
Vue.component("ModalStartup", ModalStartup);

export default {
  name: "GlobalComponents",
  data() {
    return {};
  },
};
</script>


