import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { BootstrapVueIcons } from "bootstrap-vue";
import store from "./store/store.js";
import router from "@/router/router.js";
import VueMobileDetection from "vue-mobile-detection";
import gdalServices from "@/gdal-utils.js";

Vue.config.productionTip = false;

// Add Bootstrap dependencies
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Add search-select component
import "vue-search-select/dist/VueSearchSelect.css";

// Importing the global css file
import "@/assets/css/global.css";

// Vue slider
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(IconsPlugin); // Optionally install the BootstrapVue icon components plugin
Vue.use(VueMobileDetection);
Vue.use(gdalServices);
Vue.component('VueSlider', VueSlider);

// Create an event-bus
export const eventBus = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

global.vm = App;