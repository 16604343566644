import Vue from "vue";
import loam from "loam";
import * as GeoTIFF from 'geotiff';

let gdalServices = new Vue({
  methods: {
    createSingleGeoTiff(imageData, metadata, tileMetadata, extractionData) {
      return new Promise((resolve, reject) => {
        createSingleGeoTiff(imageData, metadata, tileMetadata, extractionData)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});

async function createSingleGeoTiff(imageData, metadata, tileMetadata, extractionData) {

  // Create image without geo-reference information
  const arrayBuffer = GeoTIFF.writeArrayBuffer(imageData.data, metadata);
  var noGeoRefImage = new Blob([arrayBuffer], { type: "octet/stream" });

  // Get bounds for geo-referencing
  var georefData = null;
  if (extractionData.imageType === 'tci') {
    georefData = tileMetadata.tileBoundsMercator3857;
  } else {
    // TODO: Add information for bands
  }

  // Retrieve target CRS
  var targetCRS = null;
  if (extractionData.targetCRS === 'epsg3857') {
    targetCRS = 'EPSG:3857'
  } else if (extractionData.targetCRS === 'utmWSG84') {
    targetCRS = tileMetadata.tileBoundsUtm.epsg;
  } else if (extractionData.targetCRS === 'epsg4326') {
    targetCRS = 'EPSG:4326';
  }

  // Collected information for geo-referencing
  var geoReference = {
    defaultCRS : georefData.epsg,
    bounds : {
      lr : {lat: ""+georefData.bbox[1]+"", long: ""+georefData.bbox[2]+""},
      ul : {lat: ""+georefData.bbox[3]+"", long: ""+georefData.bbox[0]+""}
    },
    targetCRS : targetCRS
  }

  // GDAL commands

  // Load image without georeference information
  let dsWithoutRef = await loam.open(noGeoRefImage);

  // Apply default georeference information
  let dsReferencedDefault = await dsWithoutRef.convert([
    "-of", "GTiff",
    "-a_ullr", geoReference.bounds.ul.long, geoReference.bounds.ul.lat, geoReference.bounds.lr.long, geoReference.bounds.lr.lat,
    "-a_srs", geoReference.defaultCRS,
    "-b", "1",
    "-b", "2",
    "-b", "3",
  ]);

  // Change target crs (optional)
  let dsReferencedTargetCRS = await dsReferencedDefault.warp(["-s_srs", geoReference.defaultCRS, "-t_srs", targetCRS]);

  // Retrieve the GeoTiff file
  let geoTiff = await dsReferencedTargetCRS.bytes();

  // Provide download link
  var blob = new Blob([geoTiff], { type: "image/tiff" });
  let url = window.URL.createObjectURL(blob);
  
  let geoTiffFilename = extractionData.imageType.toUpperCase() + 
        '_' + extractionData.quality.toUpperCase() + 
        '_' + extractionData.tileMetadata.id.toUpperCase() +  
        '_' + extractionData.timestamp.split('T')[0];

  var a = document.createElement("a");
  a.href = url;
  a.download = geoTiffFilename;

  a.click();
  window.URL.revokeObjectURL(url);
}

export default {
  install: function (Vue) {
    Vue.prototype.$gdalServices = gdalServices;
  },
};